import React, {
  PureComponent,
} from 'react';

class Background extends PureComponent {
  render () {
    return (
      <div className='color-bg' />
    );
  }
}

export default Background;
